// src/data/issueOptions.js
const issueOptions = {
  machinery: [
    "Máy nỗi chỉ",
    "Máy gãy kim",
    "Máy không cắt chỉ",
    "Máy may không đạt tiêu chuẩn chất lượng (nhăn, vặn xếp ly, lùa....)",
    "Máy hư bàn lừa/chân vịt/các bộ phận khác của máy....",
    "Máy bị sự cố không hoạt động",
    "Máy hư dây ổ cắm",
    "Lỗi phần mềm",
    "Máy hư dao",
    "Máy hư linh kiện",
    "Máy chưa được chuẩn bị",
    "Máy còn sử dụng may mã hàng cũ",
    "Rập bị hư",
    "Cử gắn sai",
    "Máy bỏ mũi",
    "Máy lỏng chỉ",
    "Bàn ủi thiếu hơi",
    "Bàn ủi ra nước",
    "Máy hư bàn đạp",
    "Thay đổi cự ly máy",
    "Máy may bể vải",
    "Máy rối chỉ",
    "May không ôm bờ",
    "Mí không đều",
    "Máy không có điện",
    "Khác",
  ],
  people: [
    "Thực hiện không đúng thao tác",
    "Không đạt chất lượng",
    "Cử gá lắp gắn không đúng tiêu chuẩn",
    "Các thông số thiết lập máy móc không đạt chất lượng",
    "Công nhân nghỉ phép",
    "Công nhân mới",
    "Công nhân chờ công đoạn trước",
    "Công nhân chờ máy móc thiết bị",
    "Công nhân tự chỉnh máy",
    "Chờ Kỹ thuật chỉnh",
    "Công nhân nghỉ đột xuất",
    "Công nhân nằm phòng y tế",
    "Khác",
  ],
  materials: [
    "NPL cung cấp không kịp thời",
    "NPL, BTP cấp sai",
    "BTP Khác màu",
    "BTP không đạt chất lượng",
    "Hàng thêu về không kịp",
    "Hàng in về không kịp",
    "Hàng in không đạt chất lượng",
    "Cắt giao BTP không kịp",
    "Khác",
  ],
  method: [
    "Quy trình không phù hợp",
    "Chưa có quy trình",
    "Chưa chuẩn hoá thao tác",
    "Không có hướng dẫn sử dụng",
    "Thực hiện không đúng quy trình",
    "Thiếu  tiêu chuẩn",
    "Khác",
  ],
};

export default issueOptions;
