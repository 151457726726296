// src/data/peopleList.js
const peopleList = {
  factory1: [
    "CƠ ĐIỆN XN1 - NGUYỄN NGỌC THẮNG",
    "CƠ ĐIỆN XN1 - ĐINH THANH NGUYÊN",
    "CƠ ĐIỆN XN1 - LÊ QUỐC HUY",
    "CƠ ĐIỆN XN1 - PHẠM CÔNG BẰNG",
    "CƠ ĐIỆN XN1 - NGUYỄN TRƯỜNG SƠN",
    "CƠ ĐIỆN XN1 - TRẦN VĂN TÍNH",
    "CƠ ĐIỆN XN1 - TRẦN HỒNG KHANG",
    "KỸ THUẬT XN1 - TRẦN THÀNH CÔNG",
    "KỸ THUẬT XN1 - NGUYỄN THỊ HỒNG VÂN",
    "KỸ THUẬT XN1 - PHAN THỊ MỸ TIÊN",
    "KỸ THUẬT XN1 - LÊ THÀNH CHƯƠNG",
    "KỸ THUẬT XN1 - PHẠM THỊ KIM LY",
    "KỸ THUẬT XN1 - NGUYỄN THỊ KIM THANH",
    "KỸ THUẬT XN1 - NGUYỄN THỊ NGỌC TÍM",
    "KỸ THUẬT XN1 - ĐỖ THỊ HẰNG",
    "KỸ THUẬT XN1 - TRẦN THỊ THU HƯƠNG",
    "KỸ THUẬT XN1 - NGUYỄN MỸ THUẬN",
    "KỸ THUẬT XN1 - NGUYỄN THỊ CẨM NHUNG",
    "KỸ THUẬT XN1 - TRẦN VĂN LUÂN",
    "KỸ THUẬT XN1 - LÊ QUỐC THÁI",
    "KỸ THUẬT XN1 - TRẦN THỊ NGỌC GIÀU",
  ],
  factory2: [
    "CƠ ĐIỆN XN2 - NGUYỄN HOÀNG VĂN",
    "CƠ ĐIỆN XN2 - LÊ TUẤN VỸ",
    "CƠ ĐIỆN XN2 - NGUYỄN VĂN DU",
    "CƠ ĐIỆN XN2 - NGUYỄN MINH KHA",
    "CƠ ĐIỆN XN2 - LÊ MINH NHỰT",
    "CƠ ĐIỆN XN2 - BẠCH MINH HIẾU",
    "KỸ THUẬT XN2 - LÊ QUỐC TÂN",
    "KỸ THUẬT XN2 - NGUYỄN THỊ HỒNG NHUNG",
    "KỸ THUẬT XN2 - NGUYỄN THỊ NGỌC NHUNG",
    "KỸ THUẬT XN2 - HUỲNH MƠ",
    "KỸ THUẬT XN2 - NGUYỄN THỊ ANH THƯ",
    "KỸ THUẬT XN2 - VÕ THỊ NGỌC DUNG",
    "KỸ THUẬT XN2 - NGUYỄN THỊ NGỌC HUYỀN",
    "KỸ THUẬT XN2 - NGUYỄN DUY PHƯƠNG",
    "KỸ THUẬT XN2 - NGUYỄN THỊ NGỌC ÁNH",
    "KỸ THUẬT XN2 - VÕ THỊ THẢO HIỀN",
    "KỸ THUẬT XN2 - NGUYỄN THỊ HUYỀN LAN",
    "KỸ THUẬT XN2 - LÊ TRUNG HIỆP",
  ],
  teamLeaders: [
    "TỔ TRƯỞNG TỔ 01 - HUỲNH PHƯỚC KIỆT",
    "TỔ TRƯỞNG TỔ 02 - NGUYỄN THỊ KIỀU OANH",
    "TỔ TRƯỞNG TỔ 03 - NGUYỄN THỊ DỰ BẢY",
    "TỔ TRƯỞNG TỔ 04 - HUỲNH GIANG TRÚC THỦY",
    "TỔ TRƯỞNG TỔ 05 - TRẦN THỊ THANH PHƯƠNG",
    "TỔ TRƯỞNG TỔ 06 - NGUYỄN TRẦN NGỌC VÀNG",
    "TỔ TRƯỞNG TỔ 07 - NGUYỄN Y PHỤNG",
    "TỔ TRƯỞNG TỔ 08 - LÊ THANH TRÚC",
    "TỔ TRƯỞNG TỔ 09 - HỒ THỊ NGỌC THÚY",
    "TỔ TRƯỞNG TỔ 10 - TRẦN THỊ DIỄM AN",
    "TỔ TRƯỞNG TỔ 11 - PHAN THỊ KIM NGỌC",
    "TỔ TRƯỞNG TỔ 12 - TRẦN THỊ HUYỀN TRÂN",
    "TỔ TRƯỞNG TỔ 13 - VÕ THỊ THANH PHIẾN",
    "TỔ TRƯỞNG TỔ 14 - LÊ THỊ NAM ANH",
    "TỔ TRƯỞNG TỔ 15 - NGUYỄN HỒNG DUYÊN",
    "TỔ TRƯỞNG TỔ 16 - CAO PHƯƠNG HỒNG KIM VÂN",
    "TỔ TRƯỞNG TỔ 17 - NGUYỄN THỊ KIM NGỌC",
    "TỔ TRƯỞNG TỔ 17 - NGUYỄN THỊ TRÚC",
    "TỔ TRƯỞNG TỔ 18 - ",
    "TỔ TRƯỞNG TỔ 19 - NGUYỄN THỊ NGỌC DIỄM",
    "TỔ TRƯỞNG TỔ 20 - TRẦN THỊ MAI LINH",
    "TỔ TRƯỞNG TỔ 21 - NGUYỄN THỊ KIM HẰNG",
    "TỔ TRƯỞNG TỔ 22 - NGUYỄN THỊ TỐT",
    "TỔ TRƯỞNG TỔ 22 - PHAN LƯU NIỆM",
    "TỔ TRƯỞNG TỔ 23 - VÕ THỊ KIM THOA",
    "TỔ TRƯỞNG TỔ 24 - VÕ THỊ THU HÀ",
    "TỔ TRƯỞNG TỔ 24 - NGUYỄN THỊ THY THƠ",
    "TỔ TRƯỞNG TỔ 25 - ",
    "TỔ TRƯỞNG TỔ 26 - ",
    "TỔ TRƯỞNG TỔ 27 - LÊ THỊ NGỌC MÀU",
    "TỔ TRƯỞNG TỔ 27 - NGUYỄN THỊ NGỌC TRÂM",
    "TỔ TRƯỞNG TỔ 28 - NGUYỄN THỊ CẨM TÚ",
    "TỔ TRƯỞNG TỔ 29 - TRẦN THỊ ANH THƯ",
    "TỔ TRƯỞNG TỔ 30 - VÕ THỊ HỒNG ĐÀO",
    "TỔ TRƯỞNG TỔ 31 - ",
  ],
  teamViceLeaders: [
    "TỔ PHÓ TỔ 01 - NGUYỄN THỊ THÙY DUNG",
    "TỔ PHÓ TỔ 02 - NGUYỄN THỊ THƯỜNG",
    "TỔ PHÓ TỔ 06 - HỒ THỊ HỒNG ĐÀO",
    "TỔ PHÓ TỔ 13 - NGUYỄN THỊ TIÊN",
    "TỔ PHÓ TỔ 16 - DƯƠNG THỊ KIM THOA",
    "TỔ PHÓ TỔ 19 - VÕ THỊ NGỌC NHUNG",
    "TỔ PHÓ TỔ 20 - HỒ THỊ NHẬT TRINH",
    "TỔ PHÓ TỔ 24 - NGUYỄN NGỌC TIẾN",
    "TỔ PHÓ TỔ 27 - VÕ THỊ BÍCH HỒNG",
    "TỔ PHÓ TỔ 29 - LÊ VÕ MỘNG TUYỀN",
  ],
};

export default peopleList;
