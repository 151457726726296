// src/data/machineryCodes.js
const machineryCodes = {
  "Thiết bị may": [
    { value: "1K", label: "Máy 1 kim" },
    { value: "1KTL", label: "Máy 1 kim trợ lực" },
    { value: "1KBX", label: "Máy 1 kim bánh xe" },
    { value: "2K", label: "Máy 2 kim" },
    { value: "2KBX", label: "Máy 2 kim bánh xe" },
    { value: "2KĐT", label: "Máy 2 kim điện tử" },
    { value: "VS3C", label: "Máy vắt sổ 3 chỉ" },
    { value: "VS4C", label: "Máy vắt sổ 4 chỉ" },
    { value: "VS5C", label: "Máy vắt sổ 5 chỉ" },
    { value: "VS5C ĐT", label: "Máy vắt sổ 5 chỉ điện tử" },
    { value: "VS5C TL", label: "Máy vắt sổ 5 chỉ trợ lực" },
    { value: "VSMX Viền", label: "Máy vắt sổ móc xích viền" },
    { value: "VS6C", label: "Máy vắt sổ 6 chỉ" },
  ],
  "Thiết bị chuyên dùng": [
    { value: "Kansai", label: "Máy Kansai" },
    { value: "KS lược", label: "Máy Kansai lược" },
    { value: "KS TĐ", label: "Máy Kansai tự động" },
    { value: "Bọ", label: "Máy bọ" },
    { value: "Bọ MGà", label: "Máy bọ mắc gà" },
    { value: "L.Trình", label: "Máy lập trình" },
    { value: "Khuy T", label: "Máy khuy thường" },
    { value: "KhuyMP", label: "Máy khuy mắt phụng" },
    { value: "D.B 2K 1/8", label: "Máy đánh bông 2 kim 1/8" },
    { value: "D.B 2K 1/4", label: "Máy đánh bông 2 kim 1/4" },
    { value: "D.B 3K 1/4", label: "Máy đánh bông 3 kim 1/4" },
    { value: "D.B 4K 1/4", label: "Máy đánh bông 4 kim 1/4" },
    { value: "D.B 2K TĐ", label: "Máy đánh bông 2 kim tự động" },
    { value: "1KXén 5L", label: "Máy 1 kim xén 5ly" },
    { value: "1KXén 3L", label: "Máy 1 kim xén 3ly" },
    { value: "Cù chỏ 1K", label: "Cù chỏ 1 kim" },
    { value: "Cù chỏ 2K", label: "Cù chỏ 2 kim" },
    { value: "Đ.Nút", label: "Máy đính nút" },
    { value: "Dập", label: "Máy dập" },
    { value: "Ép", label: "Máy ép" },
    { value: "Ép nhỏ", label: "Máy ép nhỏ" },
    { value: "Ép đôi", label: "Máy ép đôi" },
    { value: "Ép dài", label: "Máy ép thanh dài" },
    { value: "Thả ép", label: "Máy thả ép keo" },
    { value: "Ép 2 mâm", label: "Máy ép 2 mâm" },
    { value: "Mxich1K", label: "Máy móc xích 1 kim" },
    { value: "Mxich2K", label: "Máy móc xích 2 kim" },
    { value: "Mxich 1K ĐT", label: "Máy móc xích 1 kim điện tử" },
    { value: "Ziczac", label: "Máy ziczac" },
    { value: "Flatseam", label: "Máy Flatseam" },
    { value: "Ép seam", label: "Máy ép seam" },
    { value: "Xén hàn", label: "Máy xén hàn" },
    { value: "C.Sườn 1K", label: "Máy cuốn sườn 1 kim" },
    { value: "C.Sườn 2K", label: "Máy cuốn sườn 2 kim" },
    { value: "Xâm lai", label: "Máy xâm lai" },
    { value: "Đột", label: "Máy đột" },
    { value: "Chạy rập", label: "Máy chạy rập" },
    { value: "Máy Down", label: "Máy down" },
    { value: "Máy lai", label: "Máy may lai" },
    { value: "1K xén viền", label: "Máy 1 kim xén viền" },
  ],
  "Công cụ thiết bị": [
    { value: "B.ủi", label: "Bàn ủi" },
    { value: "B.ủi tam giác", label: "Bàn ủi tam giác" },
    { value: "Bàn lazer", label: "Bàn lazer" },
    { value: "Bàn", label: "Bàn" },
  ],
};

export default machineryCodes;
