// src/pages/RemediationPage.js
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormContext } from "../context/FormContext";
import remediationOptions from "../data/remediationOptions";
import Header from "../components/Header";
import AutofillPreventer from "../components/AutofillPreventer";
import peopleList from "../data/peopleList";

const RemediationPage = () => {
  const { formData, updateFormData } = useFormContext();
  const navigate = useNavigate();

  const options = useMemo(() => {
    return remediationOptions[formData.scope] || [];
  }, [formData.scope]);

  const [filteredPeopleList, setFilteredPeopleList] = useState([]);

  useEffect(() => {
    filterPeopleList(formData.lineNumber);
  }, [formData.lineNumber]);

  const filterPeopleList = (lineNumber) => {
    if (!lineNumber) {
      setFilteredPeopleList(["CÔNG NHÂN TỰ XỬ LÝ"]);
      return;
    }

    let filteredList = [];
    let factoryList = [];

    const lineNum = parseInt(lineNumber.replace("Line ", ""));

    // Find team leaders and vice leaders for the specific line
    const teamLeaders = peopleList.teamLeaders.filter((person) =>
      person.includes(`TỔ TRƯỞNG TỔ ${lineNum.toString().padStart(2, "0")}`)
    );
    const teamViceLeaders = peopleList.teamViceLeaders.filter((person) =>
      person.includes(`TỔ PHÓ TỔ ${lineNum.toString().padStart(2, "0")}`)
    );

    // Determine which factory staff to include
    if (lineNum >= 1 && lineNum <= 16) {
      factoryList = peopleList.factory1;
    } else if (lineNum >= 17 && lineNum <= 31) {
      factoryList = peopleList.factory2;
    }

    // Special cases
    if (lineNumber === "Line 20.01") {
      const teamLeaders20_01 = peopleList.teamLeaders.filter((person) =>
        person.includes("TỔ TRƯỞNG TỔ 20.01")
      );
      filteredList = [...teamLeaders20_01, ...peopleList.factory2];
    } else if (lineNumber === "Line 20") {
      const teamLeaders20 = peopleList.teamLeaders.filter((person) =>
        person.includes("TỔ TRƯỞNG TỔ 20 -")
      );
      filteredList = [...teamLeaders20, ...peopleList.factory2];
    } else if (
      lineNumber === "Tổ hoàn thành 1 - xưởng 4" ||
      lineNumber === "Tổ hoàn thành 2 - xưởng 4"
    ) {
      const teamLeadersHT = peopleList.teamLeaders.filter((person) =>
        person.includes(
          `TỔ TRƯỞNG TỔ HOÀN THÀNH ${lineNumber.includes("1") ? "1" : "2"}`
        )
      );
      filteredList = [...teamLeadersHT, ...peopleList.factory2];
    } else {
      filteredList = [...teamLeaders, ...teamViceLeaders, ...factoryList];
    }

    filteredList.push("CÔNG NHÂN TỰ XỬ LÝ");

    setFilteredPeopleList(filteredList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.remediation ||
      (formData.remediation === "Khác" && !formData.otherRemediation) ||
      !formData.problemSolver
    )
      return;
    navigate("/responsibleperson");
  };

  return (
    <>
      <Header />
      <Container maxWidth="sm">
        <Typography variant="h5" gutterBottom>
          HÀNH ĐỘNG KHẮC PHỤC
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Phạm vi: {formData.scope}
        </Typography>
        <form onSubmit={handleSubmit} autoComplete="off">
          <AutofillPreventer />
          <FormControl component="fieldset" fullWidth margin="normal">
            <RadioGroup
              aria-label="remediation"
              name="remediation"
              value={formData.remediation}
              onChange={(e) => updateFormData({ remediation: e.target.value })}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {formData.remediation === "Khác" && (
            <TextField
              label="Nhập hành động khắc phục khác"
              value={formData.otherRemediation}
              onChange={(e) =>
                updateFormData({ otherRemediation: e.target.value })
              }
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                autoComplete: "new-password",
                endAdornment: formData.otherRemediation && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear input"
                      onClick={() => updateFormData({ otherRemediation: "" })}
                      edge="end"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Autocomplete
            options={filteredPeopleList}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chọn người giải quyết vấn đề"
                variant="outlined"
                fullWidth
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
            value={formData.problemSolver}
            onChange={(event, newValue) => {
              updateFormData({ problemSolver: newValue });
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              !formData.remediation ||
              (formData.remediation === "Khác" && !formData.otherRemediation) ||
              !formData.problemSolver
            }
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => navigate(-1)}
            style={{ marginTop: "10px" }}
          >
            Back
          </Button>
        </form>
      </Container>
    </>
  );
};

export default RemediationPage;
