// src/data/remediationOptions.js
const remediationOptions = {
  "Máy móc": [
    "Cơ điện sửa máy tại chỗ",
    "Đổi máy dự phòng",
    "Kỹ thuật điều chỉnh",
    "Nhờ máy chuyền khác tạm thời",
    "Sắp xếp người hỗ trợ",
    "Thông tin cho quản lý",
    "Khác",
  ],
  "Con người": [
    "Kỹ thuật hướng dẫn thao tác",
    "Kỹ thuật chỉnh cử, gá lắp",
    "Kỹ thuật thiết lập lại các thông số của máy",
    "Kỹ thuật chỉnh dán chân vịt/ dán cử...",
    "Kỹ thuật chỉnh lại máy",
    "Sắp xếp người hỗ trợ",
    "Kỹ thuật hướng dẫn và sắp xếp người hỗ trợ",
    "Hướng dẫn và yêu cầu kiểm tra chất lượng tại nguồn",
    "Thiết lập máy trước khi chuyển đổi",
    "Đào tạo, hướng dẫn cho công nhân",
    "Sửa hàng lỗi/hư",
    "Khác",
  ],
  "Nguyên phụ liệu": [
    "Thông tin đến quản lý các khu vực liên quan",
    "Cấp bổ sung",
    "Đổi lại NPL khác",
    "Điều tiết, hỗ trợ cho công đoạn",
    "Khác",
  ],
  "Phương pháp": [
    "Điều chỉnh lại quy trình",
    "Bổ sung các quy trình",
    "Hướng dẫn chuẩn hoá thao tác",
    "Bổ sung hướng dẫn, trực quan",
    "Họp triển khai lại",
    "Áp dụng quy trình mới",
    "Khác",
  ],
};

export default remediationOptions;
